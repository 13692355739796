import { css } from "@emotion/react";

const pathFix = css`
  path {
    transform-origin: center;
    transform-box: fill-box;
  }
`;

export default function FullLogo() {
  return (
    <svg
      className="thd-logo"
      viewBox="0 0 760 210"
      width="760"
      height="210"
      css={pathFix}
    >
      <g strokeWidth="0">
        <path d="M25 0a25 25 0 0125 25v1H25" />
        <path d="M25 25h25v25H25" />
        <path d="M25 25A25 25 0 000 50h26V25" />
        <path d="M50 50a25 25 0 0025-25H49v25" />
        <path d="M25 49h25v26H25" />
        <path d="M25 75a25 25 0 0025 25V74H25" />
        <path d="M50 100a25 25 0 0025-25H49v25" />
      </g>
      <g strokeWidth="0">
        <path d="M135 25a25 25 0 00-25 25h25V25" />
        <path d="M134 25h27v25h-27" />
        <path d="M160 25a25 25 0 0125 25h-25V25" />
        <path d="M110 49h25v27h-25" />
        <path d="M110 75a25 25 0 0025 25V75" />
        <path d="M134 75h27v25h-27" />
        <path d="M159 75h27v25h-27" />
        <path d="M160 49v27h25V49" />
        <path d="M185 100a25 25 0 0025-25h-25" />
      </g>
      <g strokeWidth="0">
        <path d="M245 0a25 25 0 0125 25v1h-25" />
        <path d="M245 25h25v25h-25" />
        <path d="M245 25a25 25 0 00-25 25h26V25" />
        <path d="M270 50a25 25 0 0025-25h-26v25" />
        <path d="M245 49h25v26h-25" />
        <path d="M245 75a25 25 0 0025 25V74h-25" />
        <path d="M270 100a25 25 0 0025-25h-26v25" />
      </g>
      <g strokeWidth="0">
        <path d="M330 75a25 25 0 0025 25V75" />
        <path d="M354 75h27v25h-27" />
        <path d="M380 100a25 25 0 0025-25v-1h-25" />
        <path d="M405 75a25 25 0 00-25-24v24" />
        <path d="M381 74h-27V51h27" />
        <path d="M330 50a25 25 0 0025 24V49h-25" />
        <path d="M330 50a25 25 0 0125-25v25" />
        <path d="M354 25h27v25h-27" />
        <path d="M380 50a25 25 0 0025-25h-25" />
      </g>
      <g strokeWidth="0">
        <path d="M440 25a25 25 0 0125 25h-25" />
        <path d="M440 49v27h25V49" />
        <path d="M440 75a25 25 0 0025 25V75" />
        <path d="M464 100h27V75h-27" />
        <path d="M490 100a25 25 0 0025-25h-25" />
        <path d="M515 76V49h-25v27" />
        <path d="M515 50a25 25 0 00-25-25v25" />
      </g>
      <g strokeWidth="0">
        <path d="M550 25a25 25 0 0125 25v1h-25" />
        <path d="M550 50a25 25 0 0025 24V50" />
        <path d="M574 50v24h27V50" />
        <path d="M600 49v27h25V49" />
        <path d="M625 50a25 25 0 00-25-25v25" />
        <path d="M600 100a25 25 0 0025-25h-25" />
        <path d="M574 100h27V75h-27" />
        <path d="M550 75a25 25 0 0025 25V75" />
      </g>
      <g strokeWidth="0">
        <path d="M685 25a25 25 0 00-25 25h25V25" />
        <path d="M684 25h27v25h-27" />
        <path d="M710 25a25 25 0 0125 25h-25V25" />
        <path d="M660 49h25v27h-25" />
        <path d="M660 75a25 25 0 0025 25V75" />
        <path d="M684 75h27v25h-27" />
        <path d="M709 75h27v25h-27" />
        <path d="M710 49v27h25V49" />
        <path d="M735 100a25 25 0 0025-25h-25" />
      </g>
      <g strokeWidth="0">
        <path d="M0 110a25 25 0 0125 25H0" />
        <path d="M0 134v27h25v-27" />
        <path d="M0 159v27h25v-27" />
        <path d="M0 184v26h25v-26" />
        <path d="M24 135h27v25H24" />
        <path d="M50 135a25 25 0 0125 25H50" />
        <path d="M50 159v27h25v-27" />
        <path d="M50 184v26h25v-26" />
      </g>
      <g strokeWidth="0">
        <path d="M135 135a25 25 0 00-25 25h25v-25" />
        <path d="M134 135h27v25h-27" />
        <path d="M160 135a25 25 0 0125 25h-25v-25" />
        <path d="M110 159h25v27h-25" />
        <path d="M110 185a25 25 0 0025 25v-25" />
        <path d="M134 185h27v25h-27" />
        <path d="M159 185h27v25h-27" />
        <path d="M160 159v27h25v-27" />
        <path d="M185 210a25 25 0 0025-25h-25" />
      </g>
      <g strokeWidth="0">
        <path d="M245 135a25 25 0 00-25 25h25v-25" />
        <path d="M244 135h27v25h-27" />
        <path d="M270 110a25 25 0 0125 25h-25" />
        <path d="M270 134v27h25v-27" />
        <path d="M220 159h25v27h-25" />
        <path d="M220 185a25 25 0 0025 25v-25" />
        <path d="M244 185h27v25h-27" />
        <path d="M269 185h27v25h-27" />
        <path d="M270 159v27h25v-27" />
        <path d="M295 210a25 25 0 0025-25h-25" />
      </g>
      <g strokeWidth="0">
        <path d="M355 135a25 25 0 00-25 25h25v-25" />
        <path d="M354 135h27v25h-27" />
        <path d="M380 110a25 25 0 0125 25h-25" />
        <path d="M380 134v27h25v-27" />
        <path d="M330 159h25v27h-25" />
        <path d="M330 185a25 25 0 0025 25v-25" />
        <path d="M354 185h27v25h-27" />
        <path d="M379 185h27v25h-27" />
        <path d="M380 159v27h25v-27" />
        <path d="M405 210a25 25 0 0025-25h-25" />
      </g>
      <g strokeWidth="0">
        <path d="M465 135a25 25 0 00-25 25h25v-25" />
        <path d="M464 135h27v25h-27" />
        <path d="M490 135a25 25 0 0125 25h-25v-25" />
        <path d="M440 159h25v27h-25" />
        <path d="M440 185a25 25 0 0025 25v-25" />
        <path d="M464 185h27v25h-27" />
        <path d="M490 159v27h25v-27" />
        <path d="M490 210a25 25 0 0025-25h-25" />
      </g>
      <g strokeWidth="0">
        <path d="M550 135a25 25 0 0125 25h-25" />
        <path d="M550 159v0 27h25v-27" />
        <path d="M550 185a25 25 0 0025 25v-25" />
        <path d="M574 210h27v-25h-27" />
        <path d="M576 186h24v-27h-24" />
        <path d="M600 160a25 25 0 00-24-25v25" />
        <path d="M600 210a25 25 0 0025-25h-25" />
        <path d="M625 186v-27h-24v27" />
        <path d="M625 160a25 25 0 00-24-25v25" />
      </g>
    </svg>
  );
}
