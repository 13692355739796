import React from "react";

export default function Head() {
  return (
    <>
      <link rel="stylesheet" href="/fonts/fontface.css" />
      <link
        rel="preload"
        href="/fonts/ZKGN-Regular-Main.woff2"
        as="font"
        type="font/woff2"
        crossOrigin="anonymous"
      />
      <link
        rel="preload"
        href="/fonts/ZKGN-Medium-Main.woff2"
        as="font"
        type="font/woff2"
        crossOrigin="anonymous"
      />
    </>
  );
}
