import { useEffect, useMemo, useState } from "react";
import styled from "@emotion/styled";
import anime from "animejs/lib/anime.es.js";
import { useRecoilState } from "recoil";
import { graphql } from "gatsby";
import AniLink from "gatsby-plugin-transition-link/AniLink";

import langAtom from "../atoms/lang";
import FullLogo from "../components/FullLogo";

import type { PageProps } from "gatsby";

const FrontPageStyles = styled.div`
  @keyframes ping {
    75%,
    to {
      transform: scale(1.5);
      opacity: 0;
    }
  }
  .animate-ping {
  }

  display: flex;
  width: calc(100vw - 64px);
  height: calc(100vh - 64px);

  .frontpage {
    margin: auto;
    max-width: 760px;

    display: flex;
    flex-direction: column;
    align-items: center;

    .thd-logo {
      width: 100%;
      height: auto;

      path {
        fill: ${(props) => props.theme.main.logo};
      }
    }

    .auxiliary {
      display: flex;
      flex-direction: column;
      align-items: center;

      width: 100%;
      height: 180px;

      position: relative;

      .motd {
        width: 100%;
        margin-top: 3rem;
        margin-bottom: 2rem;
        text-align: center;
      }

      a {
        & > * {
          border: none;
          border-radius: 10px;
          background-color: ${(props) => props.theme.main.toMain};
        }

        div.ping {
          position: absolute;
          width: 80px;
          height: 80px;
          animation: ping 2s cubic-bezier(0, 0, 0.2, 1) infinite;
        }

        button.to-main {
          position: relative;
          cursor: pointer;
          z-index: 2;

          svg path {
            stroke: white;
          }
        }
      }

      @media screen and (min-width: 835px) {
        a {
          position: absolute;
          right: 20px;
          top: -80px;
        }
      }
    }
  }
`;

type DataProps = {
  allSanityMotd: {
    nodes: {
      lang: "ja" | "en";
      message: string;
    }[];
  };
};

export default function FrontPage(props: PageProps<DataProps>) {
  const [hydrated, setHydrated] = useState(false);
  const motds = props.data.allSanityMotd.nodes;
  const [siteLang] = useRecoilState(langAtom);
  const langMotds = motds.filter((motd) => motd.lang == siteLang);

  const randomMotd = useMemo(
    () => langMotds.at(Math.floor(Math.random() * langMotds.length)),
    [],
  );

  const logoAnimation = {
    targets: ".thd-logo path",
    rotateX: () => [anime.random(-90, 90), 0],
    rotateY: [90, 0],
    duration: 500,
    delay: () => 500 + anime.random(0, 500),
    easing: "easeInOutSine",
  };
  const shiftAnimation = {
    targets: ".thd-logo",
    translateY: [80, 0],
    duration: 1000,
    easing: "easeInOutQuint",
  };
  const motdAnimation = {
    targets: ".motd",
    opacity: [0, 1],
    duration: 1000,
    easing: "easeInOutQuint",
  };
  const toMainAnimation = {
    targets: ".to-main, .ping",
    opacity: [0, 1],
    duration: 500,
    delay: 500,
    easing: "easeInOutSine",
  };

  useEffect(() => {
    setHydrated(true);
  }, []);

  useEffect(() => {
    if (hydrated) {
      anime
        .timeline()
        .add(logoAnimation)
        .add(shiftAnimation)
        .add(motdAnimation, `-=${shiftAnimation.duration}`)
        .add(toMainAnimation);
    }
  }, [hydrated]);

  if (!hydrated) return null;

  return (
    <FrontPageStyles>
      <div className="frontpage">
        <FullLogo />
        <div className="auxiliary">
          <h2 className="motd">{randomMotd?.message}</h2>
          <AniLink
            fade
            to={`/projects`}
            aria-label="Projects page"
            tabIndex="-1"
          >
            <div className="ping" />
            <button className="to-main">
              <svg width="75" height="75">
                <path
                  d="M30 20L50 37.5L30 55"
                  fill="none"
                  strokeWidth="4"
                  strokeLinecap="round"
                />
              </svg>
            </button>
          </AniLink>
        </div>
      </div>
    </FrontPageStyles>
  );
}

export const query = graphql`
  query {
    allSanityMotd {
      nodes {
        lang
        message
      }
    }
  }
`;

export { default as Head } from "../core/Head";
